.nav{
    background-color: #F2F4F5;
    --bs-nav-link-color: #001a72 !important;
    padding: 10px;
    border-radius: 30px;
}

.nav-link{
    border-radius: 30px;
    margin-left: 10px;
    margin-right: 10px;
}

.nav-link:hover{
    color: #000000 !important;
}

.nav-link.active{
    background-color: #ffffff;
    color: #001a72 !important;
}