@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700;800;900&display=swap');

:root{
  --color-primary-text: #001A72 !important;
  --color-secondary-text: #919EAB !important;
  --grey-light:#F2F4F5
}

.bg-grey{
  background-color: var(--grey-light) !important;
}

.scroller-y{
  overflow-y: scroll;
}

.horizontal-scroll{
  overflow-x: scroll; white-space: nowrap;
}
.display-inline{
  display: inline-block; width: 100%;
}

*, *::after, *::before{
  font-family: 'Mulish', sans-serif !important;
}

main{
  margin-top: 50px !important;
}


h1{
  font-weight: 800 !important;
  font-size: 25px !important;
  line-height: 25px !important;
  color: var(--color-primary-text) !important;
  margin-bottom: 40px !important;
}

p{
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #000000 !important;
  margin: 0 !important;
}


.bi-table{
  width: 100% !important;
  border-collapse: separate !important;
  border-spacing: 0 7px !important;
}
.bi-table tr{
  border-radius: 4px !important;
  transition: border 100ms ease-in-out !important;
}
.bi-table td{
  transition: border 100ms ease-in-out !important;
}
.bi-table thead th{
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #000000 !important;
}
.bi-table thead th:last-child{
  text-align: right !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: var(--color-secondary-text) !important;
}
.bi-table tbody tr:hover td{
  border-color: #001A72 !important;
  border-block-width: 2px !important;
  cursor: pointer !important;
}
.bi-table tbody tr:hover td:first-child{
  border-left-width: 2px !important;
}
.bi-table tbody tr:hover td:last-child{
  border-right-width: 2px !important;
}
.bi-table tbody tr:nth-child(2n){
  background-color: #F2F4F5 !important;
}
.bi-table tbody td{
  border-block: 1px solid #DDE3ED !important;
}
.bi-table tbody td:first-child{
  border-left: 1px solid #DDE3ED !important;
}
.bi-table tbody td:last-child{
  border-right: 1px solid #DDE3ED !important;
  text-align: right !important;
}
.bi-table th,.bi-table td{
  padding: 10px 20px !important;
}

.bi-table-signo{
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #6E7682 !important;
  text-align: center !important;
}
.bi-table-accion{
  margin-left: 20px !important;
  cursor: pointer !important;
}
.bi-table-accion:not(:hover) svg circle{
  fill: #001A72 !important;
}
.bi-table-accion:not(:hover) svg path{
  fill: white !important;
}

.bi-table.mantenedor-entre-cuentas tbody tr{
  background-color: white !important;
}
.bi-table.mantenedor-entre-cuentas tbody td:nth-child(1){
  background-color: #001A720F !important;
}
.bi-table.mantenedor-entre-cuentas tbody td:nth-child(2){
  background-color: #001A720F !important;
}






.relacion-entre-cuentas-titulo{
  position: relative !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  margin-bottom: 25px !important;
  padding-bottom: 20px !important;
}
.relacion-entre-cuentas-titulo::before{
  content: "" !important;
  position: absolute !important;
  width: calc(100% - 24px) !important;
  height: 4px !important;
  border-radius: 20px 20px 0px 0px !important;
  bottom: 0 !important;
}
table .relacion-entre-cuentas-titulo::before{
  width: 100% !important;
}
.relacion-entre-cuentas-titulo.cuenta{
  color: #001A72 !important;
}
.relacion-entre-cuentas-titulo.cuenta::before{
  background-color: #001A72 !important;
}
.relacion-entre-cuentas-titulo.codigo{
  color: #D90D39 !important;
}
.relacion-entre-cuentas-titulo.codigo::before{
  background-color: #D90D39 !important;
}
.relacion-entre-cuentas-titulo.descripcion{
  color: #6E7682 !important;
}
.relacion-entre-cuentas-titulo.descripcion::before{
  background-color: #6E7682 !important;
}

.table-reportes{
  display: flex !important;
  justify-content: center !important;
  margin-block: 40px 50px !important;
}

.table-reportes-id{
  display: flex !important;
  flex-direction: column !important;
  padding: 33px 33px 55px 33px !important;
  background: #FFFFFF !important;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
}
.table-reportes-id p{
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 30px !important;
  color: #000000 !important;
  border-bottom: 1px solid #DDE3ED !important;
}
.table-reportes-numero{
  display: flex !important;
  flex-direction: column !important;
  margin-left: 10px !important;
}
.table-reportes-numero span{
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 30px !important;
  color: #919EAB !important;
  border-bottom: 1px solid transparent !important;
}
.table-reportes-numero span:first-child{
  padding-top: 4px !important;
}

.bi-table tbody tr:nth-child(2n){
  background-color: #FFF !important;
}
.bi-table tbody tr:last-child td{
  border: none !important; 
}
.bi-table tbody tr:first-child td{
  border-top: 1px #DDE3ED solid !important;
}
.bi-table tbody tr:hover td{
  border-color: #DDE3ED !important;
  border-block-width: 1px !important;
  cursor: auto !important;
}
.bi-table tbody tr:hover td:first-child{
  border-left-width: 1px !important;
}
.bi-table tbody tr:hover td:last-child{
  border-right-width: 1px !important;
}
.bi-table tbody td{
  border-top: none !important;
  border-bottom: 1px #DDE3ED solid !important;
}
.table-asientos-wrapper{
  position: relative;
}
.table-asientos-add-row{
  padding: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  outline: transparent !important;
  border-color: transparent !important;
  border-radius: 50% !important;
  position: absolute;
  bottom: 10px;
  right: 14px;
}
.table-asientos-add-row:hover svg path{
  fill: #001A72;
}
.table-asientos{
  border-spacing: 0!important;
}
.table-asientos tbody td:nth-child(2),.table-asientos tbody td:nth-child(5),.table-asientos tbody td:nth-child(6){
  background-color: #F2F4F5;
}
.table-asientos tbody td:nth-child(1) p{
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #001A72 !important;
}
.table-asientos tbody td:nth-child(5) p,.table-asientos tbody td:nth-child(6) p{
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #000000 !important;
}
.table-asientos tbody td:nth-child(7) p{
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #14A9A0 !important;
}
.table-asientos tr.table-asientos-total td{
  background-color: #FFF !important;
}
.table-asientos tr.table-asientos-total td.table-asientos-totales{
  border-bottom: 2px solid #001A72 !important;
}
.table-asientos tr.table-asientos-total td.table-asientos-totales p{
  font-weight: 900 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #000000 !important;
}



/* bootstrap */
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
  padding-inline: 20px !important;
}
.btn{
  border-radius: 2em !important;
  font-weight: 800 !important;
}
.btn-primary{
  --bs-btn-bg: #001A72 !important;
  --bs-btn-border-color: #001A72 !important;
}
.btn-outline-primary{
  --bs-btn-color: #001A72  !important;
  --bs-btn-border-color: #001A72  !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #001A72  !important;
  --bs-btn-hover-border-color: #001A72  !important;
  --bs-btn-focus-shadow-rgb: 13,110,253 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #001A72  !important;
  --bs-btn-active-border-color: #001A72  !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #001A72  !important;
  --bs-btn-disabled-bg: transparent !important;
  --bs-btn-disabled-border-color: #001A72  !important;
  --bs-gradient: none !important;
}
.btn-secondary{
  --bs-btn-color: #6E7682 !important;
  --bs-btn-border-color: transparent !important;
  --bs-btn-bg: transparent !important;
  --bs-btn-hover-border-color: transparent !important;
  --bs-btn-hover-color: #000 !important;
  --bs-btn-hover-bg: transparent !important;
  text-decoration: underline !important;
}
.btn-danger{
  --bs-btn-color: #fff !important;
  --bs-btn-bg: #D90D39 !important;
  --bs-btn-border-color: #D90D39 !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: #bb2d3b !important;
  --bs-btn-hover-border-color: #b02a37 !important;
  --bs-btn-focus-shadow-rgb: 225,83,97 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: #b02a37 !important;
  --bs-btn-active-border-color: #a52834 !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: #D90D39 !important;
  --bs-btn-disabled-border-color: #D90D39 !important;
}
.form-control:disabled{
  background-color: #FFFFFF !important;
  border: 1px solid #DDE3ED !important;
}
.form-floating > label{
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: #6E7682 !important;
}
.form-control{
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #000 !important;
}
.form-control:disabled{
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #959595 !important;
}
.form-floating > .form-select:disabled{
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #959595 !important;
}
.form-floating > .form-select{
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  color: #000 !important;
}
textarea.form-control{
  min-height: 90px !important;
}
.navbar{
  background: #FFFFFF !important;
  border-bottom: 1px solid #DDE3ED !important;
}
.navbar-brand{
  padding-block: 15px !important;
  margin-right: 150px !important;
}
.nav-link{
  position: relative !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  color: #6E7682 !important;
  padding-inline: 35px !important;
}
.navbar-nav .nav-link.active, .navbar-nav .show > .nav-link{
  color: #000000 !important;
}
.navbar-nav .nav-link.active::before, .navbar-nav .show > .nav-link::before{
  position: absolute !important;
  content: "" !important;
  width: 20px !important;
  height: 4px !important;
  background: #001A72 !important;
  border-radius: 20px !important;
  bottom: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
.dropdown-menu{
  width: 100% !important;
}
.dropdown-item{
  text-align: center !important;
}
.offcanvas{
  background-color: #F9F9F9 !important;
}
.offcanvas-header{
  margin-top: 30px !important;
}
.offcanvas-title{
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #000000 !important;
}
.offcanvas-info{
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 35px 20px !important;
  background-color: #F2F4F5 !important;
  border-radius: 8px !important;
}
.offcanvas-info img{
  margin-bottom: 24px !important;
}
.offcanvas-info div{
  margin-bottom: 14px !important;
}
.offcanvas-info strong{
  text-align: center !important;
  display: block !important;
  font-weight: 700 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: #333840 !important;
}
.offcanvas-info p{
  text-align: center !important;
  display: block !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  color: #6E7682 !important;
}
.offcanvas-info p strong{
  text-align: center !important;
  display: block !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #6E7682 !important;
}
.offcanvas-backdrop.show{
  opacity: 0.8 !important;
}
.offcanvas .btn-close{
  position: absolute !important;
  right: 25px !important;
  top: 25px !important;
  background: none !important;
  width: 30px;
  height: 30px;
}
.text-white{
  color: #FFFFFF !important;
}
.text-red{
  color: #D90D39 !important;
}
.text-turquoise{
  color: #14A9A0 !important;
}
.text-blue{
  color: #001A72 !important;
}
.border-top-red{
  border-top: 2px solid #D90D39 !important;
}
.border-top-blue{
  border-top: 2px solid #001A72 !important;
}
.border-top-gray{
  border-top: 2px solid #6E7682 !important;
}
.border-bottom-cyan{
  border-bottom: 2px solid #14A9A0 !important;
}
.border-shadow{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.bg-blue{
  background-color: #001A72 !important;
}