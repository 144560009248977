.offcanvas{
    background-color: #F9F9F9 !important;
}

.offcanvas-body{
    background-color: #F9F9F9;
}

.offcanvas-body .card{
    background-color: #DDE3ED !important;
}

.denied .card{
    background-color: #FFFBFB !important;
    border-color: #D90D39 !important;
}